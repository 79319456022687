@import "modules/responsive-type.scss";

$primary: #002d48; /* MAIN COLOR */
$secondary: #e7134c; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 5px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

@import url('https://fonts.googleapis.com/css?family=Abril+Fatface|Play');
// font-family: 'Abril Fatface', cursive;
// font-family: 'Play', sans-serif;

html, body {
    height: 100%;
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p {
    font-family: 'Play', sans-serif;
    font-size: 1rem;
}

h1 { 
  font-family: 'Abril Fatface', cursive;
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 {
  font-family: 'Abril Fatface', cursive;  
  font-size: $mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}

h3 {
font-family: 'Abril Fatface', cursive; 
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.flash {
	display:none;
}

q,blockquote {
    quotes: "“" "”";
}

.navbarFixed {
 position: fixed;
 width: 100%;
 top: 0;
 z-index: 999;
}


/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}


nav.navbar {
	z-index: 1000;
	border: none;
	border-radius: $border-radius;
	background-color: $primary;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);


	.navbar-nav {

	    > li > a {
	    	font-family: 'Abril Fatface', cursive;
	    	// font-size: 18px;
			text-align: center;
			margin-top: 35px;
			display: flex;
			align-items: center;
			color: $wht;
			border-radius: $border-radius;



			@media (max-width: 767px) {
				margin-top: 0;
				padding: 6px 0px;
			    display: inline-block;
			}

			@media(max-width: 568px) and (orientation: landscape) {
				margin-top: 0px;
			}

		    &:focus{
            	background: transparent;
                color: $wht;
            }

            &:active {
                background: transparent;
                color: $secondary;
                outline: 0;
            }
            &:hover {

                background: $secondary;
                color: $wht;

                @media (max-width: 1024px) {
                    background: transparent;
                    color: $secondary;
                    outline: 0;
                }
            }
		}
	}
}

.navbar-right {
	margin-top: 0px;
}
.navbar-toggle {
    margin: 42px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;

    @media(max-width: 767px){
		margin: 25px 15px 8px 0px;
	}

    @media(max-width: 568px) and (orientation: landscape) {
		margin: 8px 15px 8px 0px;
	}

	@media(max-width: 320px){
		margin: 30px 15px 8px 0px;
	}
}

.btn-default:active:focus,.btn-default:active, .btn-default.focus, .btn-default:focus {
    color: #fff;
    background-color: transparent;
    border-color: none;
}

/** LOGIN FORM **/
@mixin btn {
	font-family: 'Abril Fatface', cursive !important;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;
	font-size: 1.25em;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	display: inline-block;
	margin: 2em 0em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
		text-decoration: none;
		
			@media (max-width: 1024px) {
				background: $secondary;
				color: $wht;
				box-shadow: 0px 0px 5px $blk;
			}
	}
}

.btn-default {
	@include btn;
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	input {
		border: 1px solid #777;
		text-align: center;
	}

    input#username, input#password {
  		@media (max-width: 1024px) {
   			font-size: 16px;
  		}
 	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;
		font-size: 18px;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn;
		display: block;
		width: 50%;
		font-size: 18px;
    	margin: 0 auto;
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/
footer {
	
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;
	font-size: 15px;


	a {
		font-weight: normal;
		color: $footerLinks;
		white-space: nowrap;
		font-size: 15px;
		
		@media (max-width: 990px) {
			font-size: 13px;
		}
		
		&:hover{
			color: lighten($footerLinks, 10%);
			outline: 0 !important;
 			text-decoration: none;
		}

		&:focus {
			color: $wht;
			outline: 0 !important;
 			text-decoration: none;
		}
	}

	p {
		font-weight: normal;
		font-size: 15px;

		@media (max-width: 990px) {
			font-size: 13px;
		}
	}
}


input#username, input#password {
	width: 100%;

	@media (max-width: 1024px) {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


a,a:hover,a:focus,a:active {
  outline: 0 !important;
}


.logo {
	max-width: 250px;
	padding: 0.5em;

	@media(max-width: 767px) {
		max-width: 250px;
	}

	@media (max-width: 600px) {
		max-width: 150px;
	}

	@media(max-width: 568px) and (orientation: landscape) {
		max-width: 100px;
	}

}


@media(max-width: 767px) {
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
  .navbar-toggle {
    margin-top: 20px;
  }
}

.top-pad {
	padding: 3em;
	background: white;
}

.noPad {
	padding: 0;
	margin: 0;
}

.btn {
	font-family: 'Abril Fatface', cursive;
	background-color: $secondary;
  	// border-color: white;
  	font-size: 18px;
  	padding: .7em 1.25em;
  	color: #fafafa !important;

	&:hover {
  	background-color: darken($secondary, 10%);
  	color: $wht !important;

  		@media (max-width: 1024px) {
    	// background-color: transparent !important;
    	color: #fafafa !important;
  		}
	}
}

.intro {
	background: linear-gradient(rgba(51, 51, 51, 0.7), rgba(51, 51, 51, 0.7)), url(../img/banner.jpg);
	// background-image: url(../img/banner.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	padding: 50px 0px;

	@media (max-width: 990px) {
		background-position: 99% 50%;
	}

	@media (max-width: 767px) {
		background-position: 99% 50%;
	}

	h1 {
		// font-family: 'Permanent Marker', cursive;
		font-size: 35px;
		color: $secondary;

		@media (max-width: 767px) {
			// font-size: 20px;
		}
	}

	p{
		// font-family: 'Waiting for the Sunrise', cursive;
		// font-size: 25px;
		color:$wht;

		@media (max-width: 767px) {
			// font-size: 15px;
		}
	}
}

.divider {
	
	padding: 50px 0px;

	h2 {
		// font-family: 'Permanent Marker', cursive;
		// font-size: 35px;

		@media (max-width: 767px) {
			// font-size: 20px;
		}
	}
	
	p {
		// font-family: 'Waiting for the Sunrise', cursive;
		// font-size: 25px;

		@media (max-width: 767px) {
			font-size: 15px;
		}
	}

}

.demo-2 {
    position:relative;
    width: 100%;
    padding-bottom: 55%;
    overflow: hidden;
    margin-bottom: -1px;
    text-align: center;
    background-color: $primary; 

		li { list-style: none; 
		}
}

.demo-2 p,.demo-2 h2 {
    color:#fff;
    padding:10px;
    left:-20px;
    top:20px;
    position:relative
}

.demo-2 h2 {
    // font-size: 22px;
    line-height:24px;
    margin-top: 10px;
    // font-family:'Josefin Slab', sans-serif;
    font-style: italic;
    font-weight: bold;

    @media (max-width: 1400px) {
    line-height: 0px;
    // font-size: 14px;
    margin-top: 9px;
    }
}

.effect img {
    position:absolute;
    left:0;
    bottom: 0px;
    cursor: pointer;
    width: 100%;
    -webkit-transition:bottom .3s ease-in-out;
    -moz-transition:bottom .3s ease-in-out;
    -o-transition:bottom .3s ease-in-out;
    transition:bottom .3s ease-in-out;
}

.effect img.top:hover {
    bottom: -30%;
    padding-top: 100px;

    @media (min-width: 1500px) and (max-width: 1800px) {
		bottom: -30%;
    }

    @media (max-width: 1499px) {
    	bottom: 0%;
    }

    // @media (max-width: 1005px) {
    // 	bottom: -35%;
    // }
}

.features{
	// max-width: 1400px;
	// width: 100%;
	
	p {
		// font-family: 'Permanent Marker', cursive;
		color: $wht;
	}
}

.divider2 {
	background-color: $primary;
	padding: 100px 0px;

	h2 {
		// font-family: 'Permanent Marker', cursive;
		// font-size: 35px;
		color: $wht;
	}

	@media (max-width: 767px) {
		padding: 50px 0px;
		
		h2 {
			// font-family: 'Permanent Marker', cursive;
			// font-size: 20px;

		}
	}
}

.banner {
	background: linear-gradient(rgba(51, 51, 51, 0.6), rgba(51, 51, 51, 0.6)), url(../img/banner2.jpg);
	// background-image: url(../img/banner2.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	padding: 400px 0px;

	h2 {
		// font-family: 'Permanent Marker', cursive;
		// font-size: 35px;
		color: $wht;
	}

	@media (max-width: 991px) {
		padding: 300px 0px;
		
		h2 {
			// font-family: 'Permanent Marker', cursive;
			// font-size: 28px;
			color: $wht;
		}
	}


	@media (max-width: 767px) {
		padding: 150px 0px;
		
		h2 {
			// font-family: 'Permanent Marker', cursive;
			// font-size: 25px;
			color: $wht;
		}
	}

	@media (max-width: 568px) {
		padding: 100px 0px;
		
		h2 {
			// font-family: 'Permanent Marker', cursive;
			// font-size: 20px;
			color: $wht;
		}
	}
}